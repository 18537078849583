<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Day
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="christmas-day-form">
          <v-text-field
            label="Date *"
            v-model="fields.date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('date')"
            :error-messages="errors['date']"
          ></v-text-field>
          <v-text-field
            label="Total Tickets"
            v-model="fields.total_tickets"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_tickets')"
            :error-messages="errors['total_tickets']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="christmas-day-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      day: {},
      fields: {
        date: null,
        total_tickets: null,
      },
      errors: {},
    };
  },

  methods: {
    open(day = null) {
      if (day !== null) {
        this.day = day;
        this.isEditing = true;
        this.fields.date = day.date;
        this.fields.total_tickets = day.total_tickets;
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.day = {};
      this.fields = {
        date: null,
        total_tickets: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
