<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-craigtoun-events-all' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Events</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Christmas</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-select
            label="Year"
            :items="years"
            v-model="selectedYear"
            outlined
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7">
          <v-card outlined>
            <v-card-title
              class="d-flex justify-start align-center grey lighten-4"
            >
              Purchased Tickets
              <div
                class="input-container flex-grow-1 d-flex align-center ml-8 mr-4"
              >
                <v-text-field
                  label="Search Tickets"
                  outlined
                  dense
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  background-color="white"
                ></v-text-field>
                <v-menu
                  offset-y
                  left
                  transition="slide-y-transition"
                  :close-on-click="searchFilters.menu.closeOnClick"
                  :close-on-content-click="
                    searchFilters.menu.closeOnContentClick
                  "
                >
                  <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-btn
                          icon
                          v-on="{ ...onTooltip, ...onMenu }"
                          v-bind="attrsMenu"
                        >
                          <v-icon>mdi-filter</v-icon>
                        </v-btn>
                      </template>
                      <span>Filter Search</span>
                    </v-tooltip>
                  </template>
                  <v-card
                    elevation="0"
                    color="grey lighten-5"
                    style="min-width: 200px;"
                  >
                    <v-card-subtitle class="d-flex align-center">
                      <div class="font-weight-bold">Filters</div>
                      <v-spacer></v-spacer>
                      <v-btn outlined x-small>Reset</v-btn>
                    </v-card-subtitle>
                  </v-card>
                </v-menu>
              </div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.christmasEventDialog.open()"
                  >
                    <v-icon small>mdi-ticket-outline</v-icon>
                  </v-btn>
                </template>
                <span>Assign Tickets</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="tableHeaders"
              :items="purchasedTickets"
              no-data-text="No Tickets have been Purchased yet"
            >
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    name: 'module-craigtoun-customers-individual',
                    params: { customerId: item.customer.id },
                  }"
                  >{{ item.customer.full_name }}</router-link
                >
              </template>
              <template v-slot:item.allocation_completed="{ item }">
                <v-chip
                  label
                  small
                  color="green white--text"
                  v-if="item.allocation_completed"
                  >Yes</v-chip
                >
                <v-chip label small color="red white--text" v-else>No</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      @click="$refs.christmasEventDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Ticket</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">
              Basic Information
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Event</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Ticket Cost</th>
                  <td>£2.50</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <v-card outlined>
            <v-card-title class="grey lighten-4">
              Days
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.christmasDayDialog.open()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Day</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Total Tickets</th>
                  <th>Purchased Tickets</th>
                  <th>Remaining Tickets</th>
                  <th class="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="day in days" v-bind:key="day.id">
                  <td>{{ day.formatted_dates.date }}</td>
                  <td>{{ day.total_tickets }}</td>
                  <td>{{ day.purchased_tickets }}</td>
                  <td>{{ day.remaining_tickets }}</td>
                  <td class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          class="mr-2"
                          @click="$refs.christmasDayDialog.open(day)"
                        >
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Day</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="red lighten-4 red--text"
                          v-on="on"
                        >
                          <v-icon x-small>mdi-archive</v-icon>
                        </v-btn>
                      </template>
                      <span>Archive Day</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <christmas-event-dialog ref="christmasEventDialog" />
    <christmas-day-dialog ref="christmasDayDialog" />
  </div>
</template>

<script>
import ChristmasEventDialog from "./components/ChristmasEventDialog";
import ChristmasDayDialog from "./components/ChristmasDayDialog";

export default {
  components: {
    ChristmasEventDialog,
    ChristmasDayDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Events",
          disabled: false,
          to: { name: "module-craigtoun-events-all" },
          exact: true,
        },
        {
          text: "Christmas",
          disabled: true,
        },
      ],
      searchTerm: "",
      searchFilters: {
        menu: {
          open: false,
          closeOnClick: true,
          closeOnContentClick: false,
        },
      },
      tableHeaders: [
        { text: "Customer", value: "customer" },
        { text: "Event Date", value: "formatted_dates.date" },
        { text: "Total Tickets", value: "total" },
        { text: "Preferred Time", value: "preferred_time" },
        { text: "Allocation Completed?", value: "allocation_completed" },
        { text: "Allocated Time", value: "allocated_time" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      years: [2018, 2019, 2020, 2021, 2022],
      selectedYear: 2021,
    };
  },

  computed: {
    purchasedTickets() {
      let tickets = [
        {
          id: 1,
          customer: {
            id: 1,
            full_name: "Will Scott",
            email: "will.scott@gmail.com",
          },
          total: 2,
          date: "2021-12-18",
          preferred_time: "Morning",
          allocation_completed: false,
          allocated_time: null,
          formatted_dates: {
            date: "18th Dec 2021",
          },
        },
        {
          id: 2,
          customer: {
            id: 2,
            full_name: "Kiera Russell",
            email: "kiera.russell@gmail.com",
          },
          total: 2,
          date: "2021-12-18",
          preferred_time: "Afternoon",
          allocation_completed: true,
          allocated_time: "13:15",
          formatted_dates: {
            date: "18th Dec 2021",
          },
        },
      ];

      return tickets;
    },

    days() {
      return [
        {
          id: 1,
          date: "2021-12-18",
          total_tickets: 50,
          purchased_tickets: 15,
          remaining_tickets: 35,
          formatted_dates: {
            date: "18th Dec 2021",
          },
        },
        {
          id: 2,
          date: "2021-12-19",
          total_tickets: 50,
          purchased_tickets: 15,
          remaining_tickets: 35,
          formatted_dates: {
            date: "19th Dec 2021",
          },
        },
        {
          id: 3,
          date: "2021-12-23",
          total_tickets: 50,
          purchased_tickets: 15,
          remaining_tickets: 35,
          formatted_dates: {
            date: "23rd Dec 2021",
          },
        },
        {
          id: 4,
          date: "2021-12-24",
          total_tickets: 50,
          purchased_tickets: 15,
          remaining_tickets: 35,
          formatted_dates: {
            date: "24th Dec 2021",
          },
        },
      ];
    },
  },
};
</script>
