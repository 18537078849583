<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Ticket Allocation
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="christmas-form">
          <v-select
            label="Customer *"
            v-model="fields.customer"
            :items="customers"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
            :disabled="isEditing"
            :error="errors.hasOwnProperty('customer')"
            :error-messages="errors['customer']"
          ></v-select>
          <v-select
            label="Date *"
            v-model="fields.date"
            :items="dates"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('date')"
            :error-messages="errors['date']"
          ></v-select>
          <v-text-field
            label="Total Tickets *"
            v-model="fields.total_tickets"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_tickets')"
            :error-messages="errors['total_tickets']"
          ></v-text-field>
          <v-text-field
            label="Preferred Time"
            v-model="fields.preferred_time"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('preferred_time')"
            :error-messages="errors['preferred_time']"
          ></v-text-field>
          <v-switch
            label="Allocation Completed?"
            v-model="fields.allocation_completed"
            inset
            :error="errors.hasOwnProperty('allocation_completed')"
            :error-messages="errors['allocation_completed']"
          ></v-switch>
          <v-text-field
            label="Allocated Time"
            v-model="fields.allocated_time"
            type="time"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('allocated_time')"
            :error-messages="errors['allocated_time']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="christmas-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      ticket: {},
      fields: {
        customer: null,
        date: null,
        total_tickets: null,
        preferred_time: null,
        allocation_completed: false,
        allocated_time: null,
      },
      errors: {},
    };
  },

  methods: {
    open(ticket = null) {
      if (ticket !== null) {
        this.ticket = ticket;
        this.isEditing = true;
        this.fields.customer = ticket.customer.id;
        this.fields.date = ticket.date;
        this.fields.total_tickets = ticket.total;
        this.fields.preferred_time = ticket.preferred_time;
        this.fields.allocation_completed = ticket.allocation_completed;
        this.fields.allocated_time = ticket.allocated_time;
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.ticket = {};
      this.fields = {
        customer: null,
        date: null,
        total_tickets: null,
        preferred_time: null,
        allocation_completed: false,
        allocated_time: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },

  computed: {
    dates() {
      return [
        { value: "2021-12-18", text: "18th Dec 2021" },
        { value: "2021-12-19", text: "19th Dec 2021" },
        { value: "2021-12-23", text: "23rd Dec 2021" },
        { value: "2021-12-24", text: "24th Dec 2021" },
      ];
    },

    customers() {
      return this.$store.getters["craigtoun/customersStore/all"];
    },
  },
};
</script>
